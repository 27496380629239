import { listHomeReviews } from "../services/api/customer";

class customerInteractor {
  static getInstance() {
    return this._instance;
  }
  static _instance = new customerInteractor();

  /**
   * 首页评价列表
   */
  listHomeReviewsApi() {
    return listHomeReviews();
  }
}

export default customerInteractor.getInstance();
