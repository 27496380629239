<template>
  <v-menu 
  :open-on-hover="presentationMethod === 'hover' || presentationMethod === 'all'"  
  :open-on-click="presentationMethod === 'click' || presentationMethod === 'all'"  
  :close-on-content-click="false" :maxWidth="maxWidth" :width="width" 
  :location="location" v-model="menuBol"
  :key="menuKey"
  >
    <template v-slot:activator="{ props }">
      <slot v-bind="props" name="btn" ></slot>
    </template>
    <div class="shopmall-tip-content">
      <v-row class="pb-2">
        <v-col class="pl-4 pt-0 pb-0 pr-4" v-if="store?.userInfo?.userId">
          <p class="pl-0 pt-5 pb-0 pr-0 fs-14" style="word-break: break-all;">Hello&nbsp;{{ store.userInfo.userName }}</p>
          <v-divider class="my-2"></v-divider>
          <p  v-if="store.userInfo.status == 1">
            <p class="mb-4 pointer" @click="navigateToFn('/my-account/orders',true)">My Orders</p>
            <p class="mb-4 pointer" @click="navigateToFn('/my-account/browsing-history')">Browsing History</p>
            <p class="mb-4 pointer" @click="navigateToFn('/my-account/contactus')">Customer Service</p>
            <p class="pointer mb-4"  @click="navigateToFn('/my-account/profile')">My Account</p>
          </p>
          <p  class="pa-0 fs-14 fc-333" v-if="store.userInfo?.status == 2">Your account is under review, thank you for your patience.</p>
          <p  class="pa-0 fs-14 fc-333" v-if="store.userInfo?.status == 3">We regret to inform you that your account has not been approved.</p>
        </v-col>
        <v-col :cols="12" class="pl-4 pt-6 pb-4 pr-4" v-if=" !store || !store.userInfo || store?.userInfo?.status != 1">
          <slot name="contBtn">
            <v-btn :size="width > 200 ? 'default' : 'small'" color="primary" class="w-100"  @click="viewDetail">View Details</v-btn>
          </slot>
        </v-col>
        <v-col :cols="12" class="pl-4 pt-0 pb-6 pr-4"  v-if=" !store || !store.userInfo || !store.userInfo.userId">
          <v-btn variant="outlined" :size="width > 200 ? 'default' : 'small'" color="primary" class="w-100" @click="signUp">Register</v-btn>
        </v-col>
        <p v-if="store?.userInfo?.userId" class="sp-link text-center fc-black-06 w-100 pb-3" @click="signOut">Sign Out</p>
      </v-row>
    </div>
  </v-menu>
</template>

<script setup>
import {authInteractor} from '~/core'
import { useUserStore } from "~/stores";
const props = defineProps({
  location: {
    type: String,
    default: 'bottom'
  },
  maxWidth: {
    type: [String, Number],
    default: 300
  },
  width: {
    type: [Number, String],
    default: 260
  },
  presentationMethod:{
    // hover是hove效果click是点击效果all是保持两种效果
    type:String,
    default:'hover'
  },
  isCloseBol:{
    // 是否关闭弹窗
    type:Boolean,
    default:false
  }
}) 
const router = useRouter()
const route = useRoute()
const menuBol = ref(false)
const store = useUserStore();
const menuKey = ref(1)
// 退出
const signOut = async () => {
  const [res,err] = await authInteractor.loginOutApi()
  if(res === "") {
    store.resetAll()
    if(route.path.match('product') || route.path.match('category') || route.path.match('search') || window.location.pathname === '/') {
      window.location.reload()
    } else {
      router.push({
      name:'index'
    })
    }
   
  }
};
// 登录成功点击菜单跳转
const navigateToFn=(url,params)=>{
  menuBol.value = false
  if(params){
    router.push({
      path: url,
      query: {
        status: 1,
        cp: 1,
        size: 20,
        }
    })
  }else{
    router.push({
      path: url
    })
  }
  
}
const signUp = () => {
  navigateTo("/account/signup");
};

// 点击view-detail
const { $Bus } = useNuxtApp();
const viewDetail = () => {
  menuBol.value = false
  if(route.path.match('register-status')) {
    $Bus.emit('view-detail')
  } else {
    router.push({
    name: "register-status",
  });
  }
};
const getCustomerInfo = async () => {
  const [res, err] = await authInteractor.customerDetailApi(
    store.userInfo.customerId
  );
  if (res) {
    store.setUserInfo({
      status:res.status,
    });
    menuKey.value++
  }
};
onMounted(() => {
  if(store?.userInfo?.customerId && store?.userInfo?.status != 1) getCustomerInfo();
});


</script>

<style scoped lang="scss">
.shopmall-tip-content{
  background-color: #fff;
}
</style>
