import request from "../request";
export function listHomeReviews() {
  return request({
    url: `/customer/reviews/listHomeReviews`,
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}
