import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import broadcastchannel_RdzL7gcryK from "/app/plugins/broadcastchannel.js";
import monitor_STv5px56Zi from "/app/plugins/monitor.js";
import chat_bhkMFpHxfq from "/app/plugins/chat.js";
import accessible_pZl64wpCLH from "/app/plugins/accessible.js";
import cookiebot_pJKb88ZMGB from "/app/plugins/cookiebot.js";
import plugin_1UohGbtF8v from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import buryingpoint_yKoxMksxgK from "/app/plugins/buryingpoint.js";
import bus_GHaWeQPCWC from "/app/plugins/bus.js";
import gtag_client_R70iZRv6jK from "/app/plugins/gtag.client.js";
import logistics_dBtvKYbRlq from "/app/plugins/logistics.js";
import multi_platform_KpWNtKAZqa from "/app/plugins/multi-platform.js";
import plugin_demo_rFI2U3aI0l from "/app/plugins/plugin-demo.js";
import swal_qDj5XZX5pa from "/app/plugins/swal.js";
import utils_EeQrN1BEbD from "/app/plugins/utils.js";
import validate_wD3rDnOmpV from "/app/plugins/validate.js";
import vuetify_8NhHJigKc1 from "/app/plugins/vuetify.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  broadcastchannel_RdzL7gcryK,
  monitor_STv5px56Zi,
  chat_bhkMFpHxfq,
  accessible_pZl64wpCLH,
  cookiebot_pJKb88ZMGB,
  plugin_1UohGbtF8v,
  buryingpoint_yKoxMksxgK,
  bus_GHaWeQPCWC,
  gtag_client_R70iZRv6jK,
  logistics_dBtvKYbRlq,
  multi_platform_KpWNtKAZqa,
  plugin_demo_rFI2U3aI0l,
  swal_qDj5XZX5pa,
  utils_EeQrN1BEbD,
  validate_wD3rDnOmpV,
  vuetify_8NhHJigKc1
]