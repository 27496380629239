export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("page:finish", () => {
    if (import.meta.env.VITE_NODE_ENV === "prod") {
      var s = document.createElement("script");
      var h = document.querySelector("head") || document.body;

      s.setAttribute("id", "Cookiebot");
      s.setAttribute("src", "https://consent.cookiebot.com/uc.js");
      s.setAttribute("type", "text/javascript");
      s.setAttribute("data-cbid", "d3b64d35-9292-4200-b9ea-b1f16fd4a734");
      //s.setAttribute("data-cbid", "d030160a-f162-4852-ae72-0099a0f43973"); //测试

      s.onload = function () {
        console.log("Cookiebot script loaded successfully");

        if (
          !localStorage.getItem("cookiebotRenewed") ||
          Cookiebot.consent?.method === "implied"
        ) {
          Cookiebot.renew();
        }
        setTimeout(function () {
          if (Cookiebot.consent?.method === "implied") {
            Cookiebot.renew();
          }
        }, 500);
        window.addEventListener(
          "CookiebotOnConsentReady",
          function (e) {
            localStorage.setItem("cookiebotRenewed", true);
            if (Cookiebot.consent.statistics) {
              nuxtApp.$loadGTagScript();
            } else {
              nuxtApp.$cleanupGTag();
            }
          },
          false
        );
      };
      s.onerror = function () {
        console.error("cookiebot loading failed.");
      };

      h.appendChild(s);
    }
  });
});
