import { useCartStore, useDesignStore } from "~/stores";
import { cartInteractor, productInteractor } from "~/core";
export const useCart = () => {
  const { $Utils } = useNuxtApp();
  const designStore = useDesignStore();
  return {
    getSum: list => {
      let totalQty = $Utils.sum(list, "quantity");
      let itemTotal = $Utils.sum(
        list.map(item => {
          return {
            totalPrice: item.productPrice.totalPrice * 1000
          };
        }),
        "totalPrice"
      );
      return {
        totalQty: totalQty,
        itemTotal: (itemTotal / 1000).toFixed(2)
      };
    },

    // 初始化购物车相关数据
    initCartData: async (isMerge = true) => {
      if (!cartInteractor) return;
      const cartStore = useCartStore();
      if (isMerge) {
        // 调取merge接口
        await cartInteractor.mergeApi();
      }
      // 获取购物车列表数据
      const [res, err] = await cartInteractor.countProductApi();
      if (!err) {
        cartStore.setCartCounts(res);
      }
    },

    // 预览
    preview: (product, proof) => {
      product = JSON.parse(JSON.stringify(product));
      let hide = false;
      let showToggle = false;
      if (product.ensureImgUrls) {
        product.productPrinting.printingSurfaces = product.ensureImgUrls.map(
          item => {
            return {
              printingPicUrl: item
            };
          }
        );
        hide = true;
        showToggle = true;
      } else if (!product?.productPrinting?.printingSurfaces) {
        product.productPrinting.printingSurfaces = [
          {
            printingPicUrl: product.productPicUrl
          }
        ];
        hide = true;
      }
      if (!product.code) {
        product.code = product.productInfo?.code;
      }
      proof.value.openDialog(product, hide, showToggle);
    },

    // 效果图编辑
    async editPrint(
      cartInfo,
      calculatePriceParams,
      productInfo,
      productId,
      priceInfo,
      orderProductId = ""
    ) {
      designStore.setCartInfo(cartInfo);
      designStore.setCalculatePriceParams(calculatePriceParams);
      if (priceInfo) {
        designStore.setPriceInfo(priceInfo);
      }
      // 获取产品详情
      if (orderProductId) {
        // 获取订单上的产品详情
        const [res] = await productInteractor.orderProductSnapshot({
          orderProductId
        });
        if (res) {
          designStore.setPrints(res);
        }
      } else {
        // 获取最新的产品详情
        const [res] = await productInteractor.productDetails(productId);
        if (res) {
          designStore.setPrints(res);
        }
      }

      const router = useRouter();
      const params = {
        path: `/artwork-design/${
          productInfo.code
        }-${$Utils.replaceSpacesWithDash(productInfo.nameEn)}`
      };
      let routerData = router.resolve(params);
      $Utils.jumpNewPage(routerData.href, false);
    },

    // 规格处理
    getProductSpecsDTOList() {}
  };
};
